/*
*
* Template Name: Klacks | No-Brainer
* Concept: Chat Mobile App Landing Page - Flat Background
* Files Associated: index-main.html, index-main-android.html
* Last Modified: 2019-09-21
* Author : Manoj Rajendiran
* Author Email : svencreations@gmail.com
* Author Website : http://svencreations.com/
*
*/

/* ==================================================================
* Table of Contents:
*
* 1.0 - Custom Font Declarations(if any)
* 2.0 - Simple Declarations / Fixes
* 3.0 - Basic Styles
* 4.0 - Responsive Styles
================================================================== */

/* ==================================================================
1.0 Custom Font Declarations(if any)
================================================================== */

/* ==================================================================
2.0 Simple Declarations / Fixes
================================================================== */
html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

.gpu-acc {
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

/* ==================================================================
3.0 Basic Styles
================================================================== */

/* 3.1 FullHeight Fix
-------------------------------------------------------------- */
html, body {
    height: 100%;
}

body {
    background: #4B22FF;
    color: #484245;
    font-family: "Karla", sans-serif;
}

/* 3.2 Split Screen w/ vertical centering content
-------------------------------------------------------------- */
.split-wrapper, .split-container, .split-col {
    height: 100%;
    display: auto;
}

.split-container, .split-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.split-col {
    flex: 1;
}

/* 3.3 Column Left (Logo, Heading, Content, Download Buttons, Footer Credits)
-------------------------------------------------------------- */
.col-left {
    background: #FFF;
}

.col-left .content {
    max-width: 480px;
    margin: 0 2rem;
}

.dl-wrapper {
    margin-top: 2em;
    white-space: nowrap; /* Remove this if you add more than two buttons */
}

.dl-btn {
    display: inline-block;
    margin-right: 0.25rem;
}

.dl-wrapper img {
    height: 48px;
}

h1 {
    font-size: 1.618em;
    line-height: 1.25;
    margin: 1.25em 0 0.625em;
}

p {
    font-size: 1em;
    line-height: 2;
    margin: 0;
}

.credits-info {
    padding-top: 2rem;
}

.credits-info p {
    color: #989898;
}

.credits-info a {
    color: #989898;
}

.credits-info a:hover {
    color: #4B22FF;
}

.social-links {
  margin: 0;
  padding: 2.5em 0 0;
}

.social-links li {
    position: relative;
    display: inline;
    padding-bottom: 0.1875em;
    margin-right: 0.75em;
}

.social-links li a {
    color: #989898;
    text-decoration: none;
    -webkit-transition: color 0.35s;
    -moz-transition: color 0.35s;
    -ms-transition: color 0.35s;
    -o-transition: color 0.35s;
    transition: color 0.35s;
    cursor: pointer;
}

.social-links li a:hover {
    color: #4B22FF;
}

.logo-container img {
  display: block;
}


/* 3.4 Column Right (Mobile Mockup, Screenshot slider)
-------------------------------------------------------------- */
.android-mockup {
    position: relative;
    width: 320px;
    height: 636px;
    background: url("../../images/android-bg-white.png") no-repeat left top transparent;
    background-size: contain;
}

.ios-mockup {
    position: relative;
    width: 322px;
    height: 678px;
    background: url("../../images/ios-bg-white-tall.png") no-repeat left top transparent;
    background-size: contain;
}

.drop-shadow {
    filter: drop-shadow(0px 10px 14px rgba(0,0,0,0.36));
    -webkit-filter: drop-shadow(0px 10px 14px rgba(0,0,0,0.36));
    -moz-filter: drop-shadow(0px 10px 14px rgba(0,0,0,0.36));
}

.swiper-container {
    position: absolute;
    left: 16px;
    top: 54px;
    width: 285px;
    height: 533px;
    border: 1px solid #eaeaea;
}

.ios-mockup .swiper-container {
    left: 18px;
    top: 71px;
    width: 285px;
    height: 533px;
}

.swiper-slide img {
    width: 100%;
}

/* ==================================================================
4.0 Responsive Styles
================================================================== */
@media only screen and (max-width: 1408px) {
    .ios-mockup {
        width: 299px;
        height: 632px;
    }

    .android-mockup {
        width: 302px;
        height: 600px;
    }

    .swiper-container {
        left: 15px;
        top: 51px;
        width: 285px;
        height: 501px;
    }

    .ios-mockup .swiper-container {
        left: 17px;
        top: 66px;
        width: 269px;
        height: 497px;
    }
}

@media only screen and (max-width: 1023px) {
    html, body {
        height: auto;
    }

    .split-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto;
    }

    .split-col {
        padding: 4rem 1.5rem;
        width: 100%;
    }

    .col-left .content {
        margin: 0;
    }

    .col-right {
        padding-left: 0;
        padding-right: 0;
    }
}

@media only screen and (max-width: 480px) {
  body {
    font-size: 14px;
  }
}

@media only screen and (max-width: 360px) {
  .dl-btn {
    display: block;
    margin: 0.5rem 0;
  }

  .ios-mockup, .android-mockup {
    transform: scale(1);
  }
}
