.form-box {
	padding-top: 40px;
	padding-bottom: 40px;
}

.form-wizard {
	padding: 25px;
	background: #fff;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	box-shadow: 0px 0px 6px 3px #777;
	font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #888;
    line-height: 30px;
    text-align: center;
}



/** Form Body Classic **/

.form-body-classic .form-control {
	height: 44px;
    margin: 0;
    padding: 0 20px;
    background: #f8f8f8 none repeat scroll 0 0;
    border: 1px solid #eee;
	border-radius: 0px;
    font-family: 'Roboto', FontAwesome;
    font-size: 16px;
    font-weight: 300;
    line-height: 44px;
    color: #333;
    -moz-border-radius: none; -webkit-border-radius: none; border-radius: none;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

.form-body-classic .form-control:focus {
	outline: 0;
	background: #f8f8f8 none repeat scroll 0 0;
    border: 1px solid #eee;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

.form-body-classic.form-wizard .btn {
	min-width: 105px;
	height: 40px;
    margin: 0;
    padding: 0 20px;
    border: 2px solid #ccc;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    color: #fff;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    text-shadow: none;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

/** Form Body Classic **/



/** Form Body Stylist **/

.form-body-stylist .form-control {
	height: 44px;
    margin: 0;
    padding: 0 20px;
	background: #e1e1e1; /* Old browsers */
	background: -moz-linear-gradient(top, #e1e1e1 0%, #f1f1f1 50%, #e1e1e1 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #e1e1e1 0%,#f1f1f1 50%,#e1e1e1 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #e1e1e1 0%,#f1f1f1 50%,#e1e1e1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1e1e1', endColorstr='#e1e1e1',GradientType=0 ); /* IE6-9 */
    border: 1px solid #ddd;
    font-family: 'Roboto', FontAwesome;
    font-size: 16px;
    font-weight: 300;
    line-height: 44px;
    color: #888;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

.form-body-stylist .form-control:focus {
	outline: 0;
	background: #e1e1e1; /* Old browsers */
	background: -moz-linear-gradient(top, #e1e1e1 0%, #f1f1f1 50%, #e1e1e1 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #e1e1e1 0%,#f1f1f1 50%,#e1e1e1 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #e1e1e1 0%,#f1f1f1 50%,#e1e1e1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1e1e1', endColorstr='#e1e1e1',GradientType=0 ); /* IE6-9 */
    border: 1px solid #ccc;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

.form-body-stylist.form-wizard .btn {
	margin: 0;
    padding: 3px 15px;
    border: 2px solid #ccc;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    color: #fff;
    -moz-border-radius: 0px; -webkit-border-radius: 0px; border-radius: 25px;
    text-shadow: none;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

/** Form Body Stylist **/



/** Form Body Material **/

.form-body-material .form-control {
	height: 44px;
    margin: 0;
    padding: 0 20px;
    background: #f8f8f8 none repeat scroll 0 0;
	border: 0px;
	border-bottom: 2px solid #ddd;
    font-family: 'Roboto', FontAwesome;
    font-size: 16px;
    font-weight: 300;
    line-height: 44px;
    color: #888;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

.form-body-material .form-control:focus {
	outline: 0;
	background: #f8f8f8 none repeat scroll 0 0;
    border: 0px;
	border-bottom: 2px solid #4d0085;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

.form-body-material.form-wizard .btn {
	min-width: 105px;
	height: 40px;
    margin: 0;
    padding: 0 20px;
    border: 2px solid #ccc;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    color: #fff;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    text-shadow: none;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

/** Form Body Material **/




/** Form header Classic **/

.form-header-classic .form-wizard-step-icon {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin-top: 4px;
	background: #ddd;
	font-size: 16px;
	color: #fff;
	line-height: 40px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.form-header-classic .form-wizard-step.activated .form-wizard-step-icon {
	background: #fff;
	border: 1px solid #4d0085;
	color: #4d0085;
	line-height: 38px;
}
.form-header-classic .form-wizard-step.active .form-wizard-step-icon {
	width: 48px;
	height: 48px;
	margin-top: 0;
	background: #4d0085;
	font-size: 22px;
	line-height: 48px;
}

.form-header-classic .form-wizard-step p { color: #ccc; }
.form-header-classic .form-wizard-step.activated p { color: #4d0085; }
.form-header-classic .form-wizard-step.active p { color: #4d0085; }

/** Form header Classic **/



/** Form header Modarn **/

.form-header-modarn .form-wizard-step{
	padding-top:10px !important;
	border:2px solid #fff;
	background:#ccc;
}
.form-header-modarn .form-wizard-step.active{
	background:#4d0085;
}
.form-header-modarn .form-wizard-step.activated{
	background:#4d0085;
}
.form-header-modarn .form-wizard-progress {
	height: 0px;
	display:none;
}
.form-header-modarn .form-wizard-step-icon {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin-top: 4px;
	background: #ddd;
	font-size: 16px;
	color: #777;
	line-height: 40px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.form-header-modarn .form-wizard-step.activated .form-wizard-step-icon {
	background: #4d0085;
	border: 1px solid #fff;
	color: #fff;
	line-height: 38px;
}
.form-header-modarn .form-wizard-step.active .form-wizard-step-icon {
	background: #fff;
	border: 1px solid #fff;
	color: #4d0085;
	line-height: 38px;
}

.form-header-modarn .form-wizard-step p { color: #fff; }
.form-header-modarn .form-wizard-step.activated p { color: #fff; }
.form-header-modarn .form-wizard-step.active p { color: #fff; }

/** Form header Modarn **/



/** Form header Stylist **/

.form-header-stylist .form-wizard-step{
	position:relative;
	padding-top:10px !important;
	border:0px solid #fff;
	background:#ccc;
}
.form-header-stylist .form-wizard-step::before {
	content: '';
	position: absolute;
	left: 0%;
	top: -2%;
	width: 0;
	height: 0;
	border-top: 50px solid transparent;
	border-bottom: 50px solid transparent;
	border-left: 20px solid #ccc;
	clear: both;
	z-index: 999;
}
.form-header-stylist .form-wizard-step.active::after {
	content: '';
	position: absolute;
	right: -20px;
	top: -2%;
	width: 0;
	height: 0;
	border-top: 50px solid transparent;
	border-bottom: 50px solid transparent;
	border-left: 20px solid #4d0085;
	clear: both;
	z-index: 9999;
}
.form-header-stylist .form-wizard-step.activated::after {
	content: '';
	position: absolute;
	right: -20px;
	top: -2%;
	width: 0;
	height: 0;
	border-top: 50px solid transparent;
	border-bottom: 50px solid transparent;
	border-left: 20px solid #4d0085;
	clear: both;
	z-index: 9999;
}
.form-header-stylist .form-wizard-step.active{
	background:#4d0085;
}
.form-header-stylist .form-wizard-step.activated{
	background:#4d0085;
}
.form-header-stylist .form-wizard-progress {
	height: 0px;
	display:none;
}
.form-header-stylist .form-wizard-step-icon {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin-top: 4px;
	background: #ddd;
	font-size: 16px;
	color: #777;
	line-height: 40px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.form-header-stylist .form-wizard-step.activated .form-wizard-step-icon {
	background: #4d0085;
	border: 1px solid #fff;
	color: #fff;
	line-height: 38px;
}
.form-header-stylist .form-wizard-step.active .form-wizard-step-icon {
	background: #fff;
	border: 1px solid #fff;
	color: #4d0085;
	line-height: 38px;
}

.form-header-stylist .form-wizard-step p { color: #fff; }
.form-header-stylist .form-wizard-step.activated p { color: #fff; }
.form-header-stylist .form-wizard-step.active p { color: #fff; }

/** Form header Stylist **/





.form-wizard strong { font-weight: 500; }

.form-wizard a, .form-wizard a:hover, .form-wizard a:focus {
	color: #4d0085;
	text-decoration: none;
}

.form-wizard h1, .form-wizard h2 {
	margin-top: 10px;
	font-size: 38px;
    font-weight: 100;
    color: #555;
    line-height: 50px;
}

.form-wizard h3 {
	font-size: 25px;
    font-weight: 300;
    color: #4d0085;
    line-height: 30px;
	margin-top: 0;
	margin-bottom: 5px;
	text-transform: uppercase;
}

.form-wizard h4 {
	float:left;
	font-size: 20px;
    font-weight: 300;
    color: #4d0085;
    line-height: 26px;
	width:100%;
}
.form-wizard h4  span{
	float:right;
	font-size: 18px;
    font-weight: 300;
    color: #555;
    line-height: 26px;
}

.form-wizard table tr th{font-weight:normal;}

.form-wizard img { max-width: 100%; }

.form-wizard ::-moz-selection { background: #4d0085; color: #fff; text-shadow: none; }
.form-wizard ::selection { background: #4d0085; color: #fff; text-shadow: none; }

.checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"], .radio input[type="radio"], .radio-inline input[type="radio"] {
	position: absolute;
	margin-top: 9px;
	margin-left: -20px;
}

.form-control option:hover, .form-control option:checked  {
    box-shadow: 0 0 10px 100px #4d0085 inset;
}

.form-control:-moz-placeholder { color: #888; }
.form-control:-ms-input-placeholder { color: #888; }
.form-control::-webkit-input-placeholder { color: #888; }

.form-wizard label { font-weight: 300; }
.form-wizard label span { color:#4d0085; }

.form-wizard .btn:hover {
	background:#f34727;
	color: #fff;
	}
.form-wizard .btn:active {
	outline: 0;
	background:#f34727;
	color: #fff;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	}
.form-wizard .btn:focus,
.form-wizard .btn:active:focus,
.form-wizard .btn.active:focus {
	outline: 0;
	background:#f34727;
	color: #fff;
}

.form-wizard .btn.btn-next,
.form-wizard .btn.btn-next:focus,
.form-wizard .btn.btn-next:active:focus,
.form-wizard .btn.btn-next.active:focus {
background: #4d0085;
}

.form-wizard .btn.btn-submit,
.form-wizard .btn.btn-submit:focus,
.form-wizard .btn.btn-submit:active:focus,
.form-wizard .btn.btn-submit.active:focus {
background: #4d0085;
}

.form-wizard .btn.btn-previous,
.form-wizard .btn.btn-previous:focus,
.form-wizard .btn.btn-previous:active:focus,
.form-wizard .btn.btn-previous.active:focus {
background: #bbb;
}

.form-wizard .success h3{
	color: #4d0085;
	text-align: center;
	margin: 20px auto !important;
}
.form-wizard .success .success-icon {
	color: #4d0085;
	font-size: 100px;
	border: 5px solid #4d0085;
	border-radius: 100px;
	text-align: center !important;
	width: 110px;
	margin: 25px auto;
}
.form-wizard .progress-bar {
	background-color: #4d0085;
}

.form-wizard-steps{
	margin:auto;
	overflow: hidden;
	position: relative;
	margin-top: 20px;
}

.form-wizard-progress {
	position: absolute;
	top: 24px;
	left: 0;
	width: 100%;
	height: 1px;
	background: #ddd;
}
.form-wizard-progress-line {
	position: absolute;
	top: 0;
	left: 0;
	height: 1px;
	background: #4d0085;
}

.form-wizard-tolal-steps-2 .form-wizard-step {
	position: relative;
	float: left;
	width: 50%;
	padding: 0 5px;
}
.form-wizard-tolal-steps-3 .form-wizard-step {
	position: relative;
	float: left;
	width: 33.33%;
	padding: 0 5px;
}
.form-wizard-tolal-steps-4 .form-wizard-step {
	position: relative;
	float: left;
	width: 25%;
	padding: 0 5px;
}
.form-wizard-tolal-steps-5 .form-wizard-step {
	position: relative;
	float: left;
	width: 20%;
	padding: 0 5px;
}
.form-wizard-tolal-steps-6 .form-wizard-step {
	position: relative;
	float: left;
	width: 16.66%;
	padding: 0 5px;
}
.form-wizard-tolal-steps-7 .form-wizard-step {
	position: relative;
	float: left;
	width: 14.285%;
	padding: 0 5px;
}

.form-wizard fieldset {
	display: none;
	text-align: left;
	border:0px !important
}

.form-wizard-buttons { text-align: right; }

.form-wizard .input-error { border-color: #4d0085;}

/** image uploader **/
.image-upload a[data-action] {
  cursor: pointer;
  color: #555;
  font-size: 18px;
  line-height: 24px;
  transition: color 0.2s;
}
.image-upload a[data-action] i {
  width: 1.25em;
  text-align: center;
}
.image-upload a[data-action]:hover {
  color: #4d0085;
}
.image-upload a[data-action].disabled {
  opacity: 0.35;
  cursor: default;
}
.image-upload a[data-action].disabled:hover {
  color: #555;
}
.settings_wrap{
	margin-top:20px;
}
.image_picker .settings_wrap {
  overflow: hidden;
  position: relative;
}
.image_picker .settings_wrap .drop_target,
.image_picker .settings_wrap .settings_actions {
  float: left;
}
.image_picker .settings_wrap .drop_target {
  margin-right: 18px;
}
.image_picker .settings_wrap .settings_actions {
	float: left;
	margin-top: 100px;
	margin-left: 20px;
}
.settings_actions.vertical a {
  display: block;
}
.drop_target {
	position: relative;
	cursor: pointer;
	transition: all 0.2s;
    width: 250px;
    height: 250px;
    background: #f2f2f2;
    border-radius: 100%;
    margin: 0 auto 25px auto;
    overflow: hidden;
    border: 8px solid #E0E0E0;
}
.drop_target input[type="file"] {
  visibility: hidden;
}
.drop_target::before {
	content: 'Drop Hear';
	font-family: FontAwesome;
	position: absolute;
	display: block;
	width: 100%;
	line-height: 220px;
	text-align: center;
	font-size: 40px;
	color: rgba(0, 0, 0, 0.3);
	transition: color 0.2s;
}
.drop_target:hover,
.drop_target.dropping {
  background: #f80;
  border-top-color: #cc6d00;
}
.drop_target:hover:before,
.drop_target.dropping:before {
  color: rgba(0, 0, 0, 0.6);
}
.drop_target .image_preview {
  width: 100%;
  height: 100%;
  background: no-repeat center;
  background-size: contain;
  position: relative;
  z-index: 2;
}
