html, body {
    min-height: 100% !important;
    height: 100%;
    display: grid;
}
body {
    background: transparent;
    line-height: 1em;
}
body.modal-open {
  overflow: hidden;
}

.form-body-classic .form-control {
    height: auto;
}

#byoCharity {
    display: hide;
}

#intro {
    background: #fff;
}

.np-right {
    padding-right: 0;
}

.form-wizard {
    box-shadow: none !important;
    background: transparent;
}

#section-registration {
    background: #fff;
}

#phone-demo {
    display:none;
    background: #4d0085;
}

.form-wizard {
    padding: 0;
}

.btn-next {
    background: #4d0085;
    color: #fff;
}

.btn-next:hover, .btn-next:focus {
    color: #ddd;
}

.radio-margin {
    margin-right: 5px;
}

.videoplayer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0,0,0, 0.9);
}

.videoplayer .video {
    height: 100%;
    width: 100%;
    line-height: 1px;
    text-align: center;
    display: inline-block;
}

.videoplayer .video:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px;
}

.videoplayer .bg {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative;
}

.videoplayer iframe {
    width: 100%!important;
    height: 100%!important;
    position: absolute;
    top: 0;
    left: 0;
}

.videoplayer .video-container {
    background: 0 0;
    max-width: 1140px;
    max-height: 100%;
    width: 100%;
    padding: 5%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 1080px;
    display: inline-block;
    height: auto;
    vertical-align: middle;
}

.videoplayer .close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    background-repeat: no-repeat;
    border: none!important;
    text-decoration: none!important;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-image: url(https://beta.starshoutout.com/wp-content/themes/recond/assets/images/swipebox/icons.svg);
    background-position: 15px 12px;
}

.white {
    background-color: #fff !important;
    display: block;
    width: 100%;
    max-width: 100%;
    flex: auto;
    padding: 1em 1.5em;
    word-break: break-all;
}

#phone-demo .ios-mockup {
    background: transparent;
    width: 322px;
    height: 678px;
}

#phone-demo .ios-mockup .phone-image {
    background: url(../../images/g-white.png) no-repeat left top transparent;
    background-size: contain;
    width: 283px;
    z-index: 999;
    height: 576px;
    display: block;
    position: absolute;
    top: -16px;
    left: -16px;
}
#phone-demo .ios-mockup .swiper-container {
    width: 255px;
    height: 552px;
    z-index: 0;
    border: 0;
    overflow: visible;
    top: 16px;
    left: 16px;
}

#phone-demo iframe {
    background: #000;
}

.form-wizard fieldset {
  display: block;
}
