/*
*
* Template Name: Klacks | No-Brainer
* Description: A bunch of super minimal HTML Templates
* Files Associated: index-404-text-animated.html, index-d4sl-text-animated.html, index-personal-text-animated.html
* Date Modified: 2019-09-16
* Authors: Sven Creations(svencreations@gmail.com)
*
*/

/* ==================================================================
* Table of Contents:
*
* 1.0 - Custom Font Declarations(if any)
* 2.0 - Simple Fixes
* 3.0 - Basic Styles
* 		3.1 - Main Content
* 		3.2 - Preloader Spinner
*       3.3 - Color Palettes and Font Declaration
* 4.0 - Responsive Styles
* 		4.1 - Small Devices, Tablets (>=768px)
* 		4.2 - Medium Devices, Desktops (>=992px)
* 		4.3 - Large Devices, Desktops (>=1200px)
*       4.4 - Wide Screens, Desktops (>=1824px)

================================================================== */

/* ==================================================================
1.0 Custom Font Declarations(if any)
================================================================== */

/* ==================================================================
2.0 Simple Fixes
================================================================== */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* Set the body and html to full screen height */
html, body {
    height: 100%;
}

/* Remove dotted borders around the links when clicked */
a, a:active, a:focus, input:focus {
    text-decoration: none !important;
    outline: 0;
}

::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.1);
}

:-moz-placeholder { /* Firefox 18- */
    color: rgba(255, 255, 255, 0.1);
}

::-moz-placeholder {  /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.1);
}

:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.1);
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius:  0 !important;
}

.gpu-hack {
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden
}

.bg-cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 0;
    visibility: hidden;
}

.bg-cover::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(270deg,rgba(29,31,40,0.75) 0%,rgba(29,31,40,0.95) 100%);
    background: -webkit-linear-gradient(270deg,rgba(29,31,40,0.75) 0%,rgba(29,31,40,0.95) 100%);
    background: -o-linear-gradient(270deg,rgba(29,31,40,0.75) 0%,rgba(29,31,40,0.95) 100%);
    background: -ms-linear-gradient(270deg,rgba(29,31,40,0.75) 0%,rgba(29,31,40,0.95) 100%);
    background: linear-gradient(180deg,rgba(29,31,40,0.75) 0%,rgba(29,31,40,0.95) 100%);
}

div[class^="sven-char"] {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    outline: 1px solid transparent
}

/* Horizontal and vertical center content */
.abs-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%); /* IE 9 */
    -webkit-transform: translate(-50%,-50%); /* Safari */
    -moz-transform: translate(-50%,-50%); /* Firefox Older versions*/
    -o-transform: translate(-50%,-50%); /* Opera */
    transform: translate(-50%,-50%);
}

.mt-0 {
    margin-top: 0;
}

/* ==================================================================
3.0 Basic Styles
BaseFont 16px; BaseLine 30px; ScaleFactor 1.333 (Perfect Fourth)
================================================================== */
body {
    padding: 0;
    margin: 0;
    background: #151515;
    color: #fff;
    font-size: 16px;
    line-height: 30px;
}

/* 3.1 Main Content
-------------------------------------------------------------- */
.content-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 1;
}

.content-wrapper::before {
    content: '';
    display: inline-block;
    height: 100%;
    margin-right: -0.25em;
    vertical-align: middle;
}

.v-center {
    position: relative;
    display: inline-block;
    width: 86%;
    text-align: left;
    vertical-align: middle;
}

/* Content Headings, Paragraphs */
.main-content {
    visibility: hidden;
}

p {
    font-size: 1em;
    line-height: 1.875em;
    margin: 0;
    padding: 0 0 0.9375em 0;
}

h3 {
    margin-bottom: 0.714em;
    padding: 0;
    font-size: 1.333em;
    line-height: 1.429em;
}

p a {
    color: #DDC225;
}

/* Social Links, Buttons, Input Elements */
ul {
    padding: 0;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    font-size: 0.75em;
}

ul li {
    position: relative;
    display: inline;
    padding-bottom: 0.1875em;
    margin-right: 0.75em;
}

li a {
    color: #A3A3A3;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -ms-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
    cursor: pointer;
}

li a:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 0.125em;
    background-color: #DDC225;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -ms-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
}

li a:hover {
    color: #FFF;
}

li a:hover:after {
    opacity: 1;
}

a.btn-fill, a.btn-bordered {
    padding: 0.5em 1em;
    border: 0.063em solid transparent;
    border-radius: 0.125em;
    font-weight: 700;
    text-transform: uppercase;
}

a.btn-fill:after, a.btn-bordered:after {
    display: none;
}

a.btn-fill {
    color: #00A1E0;
    background: #FFD61F;
}

a.btn-fill:hover {
    background: #FFF;
    color: #00A1E0;
}

a.btn-bordered {
    color: #FFF;
    border-color: #FFF;
}

#drifter, #particles-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow:hidden;
}

.sven-badge {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 2em;
    margin: 0 auto;
    width: 86%;
    font-size: 0.438em;
    line-height: 1.875em;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    z-index: 10;
}

.sven-badge a {
    color: #707070;
}

.sven-badge span {
    display: inline-block;
    font-weight: bold;
    border-bottom: 1px dotted;
}

.sven-badge:hover span{
    color: #DDC225;
    border-bottom: 1px dotted transparent;
}

/* 3.2 Preloader Spinner
https://codepen.io/glosie/pen/GgqBXR
-------------------------------------------------------------- */
.preloader {
    z-index: 100;
}

.spinner {
    position: relative;
    width: 1em;
    height: 1em;
    margin: 0 auto;
    border-radius: 50%;
    background: #DDC225;
    background: -moz-linear-gradient(top, #DDC225 10%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(top, #DDC225 10%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to bottom, #DDC225 10%, rgba(255, 255, 255, 0) 100%);
    -webkit-animation: load 1s infinite linear;
    -moz-animation: load 1s infinite linear;
    animation: load 1s infinite linear;
}

.spinner:before, .spinner:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.spinner:before {
    border-radius: 100%;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 50%, #DDC225 50%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 50%, #DDC225 50%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #DDC225 50%);
}

.spinner:after {
    width: 90%;
    height: 90%;
    margin: auto;
    border-radius: 50%;
    background: #151515;
}

@-webkit-keyframes load {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-moz-keyframes load {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@keyframes load {
    from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* 3.3 Color Palettes and Font Declaration
-------------------------------------------------------------- */
#minimos-1 {
    font-family: "Raleway", sans-serif;
    background: #151515;
}

#minimos-2, #minimos-2 .spinner:after {
    font-family: "Abel", sans-serif;
    background: #280B29;
}

#minimos-3, #minimos-3 .spinner:after {
    font-family: "Nunito", sans-serif;
    background: #00A1E0;
}

#minimos-3 .sven-badge a {
    color: #CCCCCC;
}

#minimos-3 .sven-badge:hover span {
    color: #FFD61F;
}

#minimos-4, #minimos-4 .spinner:after {
    font-family: "Josefin Sans", sans-serif;
    background: #F85E25;
}

#minimos-4 .sven-badge a {
    color: #CCCCCC;
}

#minimos-4 .sven-badge:hover span {
    color: #F8EB31;
}

#minimos-4 li a {
    color: #E0E0E0;
}

#minimos-4 li a:hover {
    color: #FFFFFF;
}

/* 3.4 Layout Alignment
-------------------------------------------------------------- */
.layout-center .v-center, .layout-center .sven-badge {
    text-align: center;
}

.layout-center .main-content {
    margin: 0 auto;
}


/* ==================================================================
4.0 Responsive Styles
================================================================== */

/* 8.1 Small Devices, Tablets (>=768px)
-------------------------------------------------------------- */
@media only screen and (min-width : 768px) {
    body {
        font-size: 22px;
        line-height: 48px;
    }

    .main-content {
        width: 80%;
    }
}

/* 8.2 Medium Devices, Desktops (>=992px)
-------------------------------------------------------------- */
@media only screen and (min-width : 992px) {

}

/* 8.3 Large Devices, Desktops (>=1200px)
-------------------------------------------------------------- */
@media only screen and (min-width : 1200px) {
    body {
        font-size: 26px;
        line-height: 48px;
    }
}

/* 8.4 Wide Screens, Desktops (>=1824px)
-------------------------------------------------------------- */
@media only screen and (min-width : 1824px) {
    body {
        font-size: 32px;
        line-height: 60px;
    }
}
